import React from "react"
import Layout from '../components/global/layout.js'
import imprintStyles from '../style/imprint.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Helmet } from "react-helmet"



const ImprintPage = () => {

    return (

        <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title> Spherical Elephant | Impressum </title>
                <meta name="description" content="Spherical Elephant GmbH | Schottenfeldgasse 14/2a 1070 Wien, Österreich | Full-stack Design & Development"></meta>
            </Helmet>

            <div className={imprintStyles.headerbar}>
                <h1 className={imprintStyles.title}>
                    <FormattedMessage id="Imprint.title" />
                </h1>
            </div>
            <div className={imprintStyles.imprint}>
                <div>
                    <div>
                        <p><FormattedMessage id="Imprint.row1.key" /> <FormattedMessage id="Imprint.row1.value" /></p>
                        <p><FormattedMessage id="Imprint.row2.key" /> <FormattedMessage id="Imprint.row2.value" /></p>
                        <p><FormattedMessage id="Imprint.row3.key" /> <FormattedMessage id="Imprint.row3.value" /></p>
                        <p><FormattedMessage id="Imprint.row4.key" /> <FormattedMessage id="Imprint.row4.value" /></p>
                        <p><FormattedMessage id="Imprint.row5.key" /> <FormattedMessage id="Imprint.row5.value" /></p>
                        <p><FormattedMessage id="Imprint.row6.key" /> <FormattedMessage id="Imprint.row6.value" /></p>
                        <p><FormattedMessage id="Imprint.row7.key" /> <FormattedMessage id="Imprint.row7.value" /></p>
                        <p><FormattedMessage id="Imprint.row8.key" /></p>
                        <p><FormattedMessage id="Imprint.row9.key" />  <a href = "https://www.sphericalelephant.com/de/tel:+4319240281"><FormattedMessage id="Imprint.row9.value" /></a></p>
                        <p><FormattedMessage id="Imprint.row10.key" /> <a href = "mailto:office@sphericalelephant.com"><FormattedMessage id="Imprint.row10.value" /></a></p>
                        <p><FormattedMessage id="Imprint.row11.key" /> <FormattedMessage id="Imprint.row11.value" /></p>
                        <p><FormattedMessage id="Imprint.row12.key" /> <a href = "www.ris.bka.gv.at" target = "_blank"><FormattedMessage id="Imprint.row12.value" /></a></p>
                        <p><FormattedMessage id="Imprint.row13.key" /> <FormattedMessage id="Imprint.row13.value" /></p>
                        <p><FormattedMessage id="Imprint.row14.key" />  <a href = "ec.europa.eu/odr" target = "_blank"><FormattedMessage id="Imprint.row14.value" /></a></p>
                        <p><FormattedMessage id="Imprint.row15" /></p>
                    </div>
                </div>
            </div>
        </Layout>


    )
}

export default ImprintPage
